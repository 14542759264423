import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';  // Import the xlsx library


const ReportComponent = () => {
  const [reportList, setReportList] = useState([]);
  const [selectedReport, setSelectedReport] = useState('');
  const [query, setQuery] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const itemsPerPage = 8;

  useEffect(() => {
    const fetchReportList = async () => {
      try {
        const response = await fetch('https://rainwaterharvesting-backend-1.onrender.com/getReportList');
        const result = await response.json();
        if (result.code === 200) {
          setReportList(result.data);
        } else {
          console.error('Failed to fetch report list:', result.message);
        }
      } catch (error) {
        console.error('Error fetching report list:', error);
      }
    };

    fetchReportList();
  }, []);

  const handleReportData = async () => {
    if (!selectedReport) {
      alert('Please select a report type.');
      return;
    }
    try {
      const url = `https://rainwaterharvesting-backend-1.onrender.com/getReportData?REPORTTYPE=${encodeURIComponent(selectedReport)}`;
      const response = await fetch(url);
      const result = await response.json();
      if (result.code === 200) {
        setQuery(result.data);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filteredData = query.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const exportToExcel = () => {
    // Create a new worksheet
    const ws = XLSX.utils.json_to_sheet(filteredData);
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report Data');
    // Write the Excel file to a binary string and trigger a download
    XLSX.writeFile(wb, 'ReportData.xlsx');
  };

  return (
    <div className="container mt-5">
      {/* <h1 className="mb-4">Generate Report</h1> */}

      {/* Select and Button Row */}
      <div className="d-flex align-items-center justify-content-around mb-4">
        <select
          className="form-select me-3 w-25"
          value={selectedReport}
          onChange={(e) => setSelectedReport(e.target.value)}
        >
          <option value="">Select Report Type</option>
          {reportList.map((report) => (
            <option key={report.ID} value={report.REPORT_TYPE}>
              {report.REPORT_TYPE}
            </option>
          ))}
        </select>
        <button
          className="btn btn-primary w-25"
          onClick={handleReportData}
        >
          Generate Report
        </button >
        {query.length > 0 && (
        <div className="">
          <button
            className="btn btn-success"
            onClick={exportToExcel}
          >
            Download
          </button>
        </div>
       )} 
        <button className='btn btn-danger w-25'
        onClick={() => navigate('/')}
        >Back To Home</button>
      </div>

      {/* Search Bar */}
      {query.length > 0 && (
        <input
          type="text"
          className="form-control mb-4 w-25"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}

      {/* Table */}
      {query.length > 0 && (
        <div className="table-responsive">
          <table className="table text-center table-striped table-bordered border-4">
            <thead className="table-primary">
              <tr>
                {Object.keys(query[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      {query.length > itemsPerPage && (
        <div className="d-flex justify-content-center  align-items-center mt-1 ">
          <button
            className="btn btn-outline-primary mx-2 "
            style={{width:"100px"}}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <div style={{width:"100px"}}>
            Page {currentPage} of {totalPages}
          </div>
          <button
            className="btn btn-outline-primary "
            style={{width:"100px"}}
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
       {/* Download Button */}
     
    </div>
    
  );
};

export default ReportComponent;
