import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Image } from 'react-bootstrap';

const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0'); // Ensure 2 digits
    const day = `${d.getDate()}`.padStart(2, '0'); // Ensure 2 digits
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };
  
  const MyModal = ({ picklistOptions, rowData, triggerModalVisibility }) => {
    const [show, setShow] = useState(true);
    // const [location, setLocation] = useState({ latitude: null, longitude: null });
    const[latitude,setLatitude] = useState("");
    const[longitude,setLongitude] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    

    const notifyUser = (message) => {
        // Your notification logic here (e.g., toast, alert)
        alert(message); // Replace with a better notification system
    };
    



    // Initialize formData with selectedData (default values)
    const [formData, setFormData] = useState({
      district: '',
      taluka: '',
      village: '',
      location: '',
      inaugurationDate: '',
      inaugurationPhoto: null,
      inaugurationPhotoBase64: '', // For storing the base64 string
      completionDate: '',
      workName:'',
      completionPhoto: null,
      completionPhotoBase64: '', // For storing the base64 string
      latitude:'',
      longitude:'',
      APPROX_AMOUNT:'',
      AreaType : '',
      Communities_Involved_Name : '',
      Work_Status : '',
      MOBILE_NO : '',
      IMPLIMANTATION_AUTHORITY: ' ',

      


    });
  
    const [picklistValues, setPicklistValues] = useState({
      district: [],
      taluka: [],
      village: [],
    });
  
    const handleClose = () => {
        setShow(false)
        triggerModalVisibility();
    };
    const handleShow = () => setShow(true);

    // Helper to convert file to base64
    const convertToBase64 = (file, name) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFormData(prevState => ({
          ...prevState,
          [name]: file, // Store the file
          [`${name}Base64`]: reader.result, // Store base64 string
        }));
      };
    };
  
    const handleChange = (e) => {
      const { name, value, files } = e.target;
      if (files && files.length > 0) {
        convertToBase64(files[0], name); // Convert the file to base64
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };

    console.log(33,formData);
    const handleAmount = (event) => {
      const value = event.target.value;
    
      // Check if the value is a valid number or an empty string
      const numericValue = value === '' ? '' : parseFloat(value);
    
      // Update the state with the numeric value
      setFormData(prevData => ({
        ...prevData,
        APPROX_AMOUNT: numericValue
      }));
    };
   
  
    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Map formData fields to match your database columns
        const mappedData = {
          ID:rowData.id,
          DISTRICT: formData.district,
          TALUKA: formData.taluka,
          VILLAGE: formData.village,
          LOCATION: formData.location,
          Inauguration_DATE: formData.inaugurationDate,
          inaugurationPhotoBase64: formData.inaugurationPhotoBase64, // base64 encoded image
          COMPLETED_DATE: formData.completionDate,
          completionPhotoBase64: formData.completionPhotoBase64,
          WORK_NAME:formData.workName,
          Latitude:formData.latitude,
          Longitude:formData.longitude, // base64 encoded image
          APPROX_AMOUNT:formData.APPROX_AMOUNT,
          Area_Type : formData.AreaType,
          Communities_Involved_Name : formData.Communities_Involved_Name,
          Work_Status : formData.Work_Status,
          MOBILE_NO : formData.MOBILE_NO,
          IMPLIMANTATION_AUTHORITY: formData.IMPLIMANTATION_AUTHORITY,
          LAST_UPD_ID: formData.LAST_UPD_ID
        };


        Object.keys(mappedData).forEach((key)=>{
          console.log(81,mappedData[key]);
          if(!mappedData[key] && mappedData[key]?.length === 0){
            delete mappedData[key]
          }
        })
      
        console.log(mappedData);
      
        // Send the mappedData to your backend API
        fetch('https://rainwaterharvesting-backend-1.onrender.com/newupdateRecords', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(mappedData),
        })
          .then((response) => response.json())
          .then((data) => {
            alert('Records Update Successfull');
          })
          .catch((error) => {
            alert('Update Error:', error);
          });
      
        handleClose();
      };
  
    useEffect(() => {
      // Populate picklist values from props
      setPicklistValues({ ...picklistOptions });
      
  
      // Populate formData with selectedData, format the dates properly
      console.log(227,rowData);
      setFormData({
        district: rowData.district || '',
        taluka: rowData.taluka || '',
        village: rowData.village || '',
        location: rowData.location || '',
        inaugurationDate: formatDate(rowData.inaugurationDate), // Format date
        inaugurationPhoto: rowData.inaugurationPhoto || null,
        completionDate: formatDate(rowData.completionDate), // Format date
        completionPhoto: rowData.completionPhoto || null,
        workName:rowData.work || null,
        IMPLIMANTATION_AUTHORITY:rowData.implementationAuthority || null,
        latitude : rowData.latitude || '',
        longitude : rowData.longitude || '',
        APPROX_AMOUNT : rowData.APPROX_AMOUNT || '',
        AreaType : rowData.AreaType || '',
        Communities_Involved_Name : rowData.Communities_Involved_Name || '',
        Work_Status : rowData.Work_Status,
        MOBILE_NO : rowData.MOBILE_NO

      });
      setLatitude(rowData.latitude);
      setLongitude(rowData.longitude);
      if (rowData.completionDate) {
        setFormData((prev) => ({
          ...prev,
          Work_Status: "Completed",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          Work_Status: "On Going",
        }));
      }
    }, [picklistOptions, rowData]);


  const handleLocationCapture = (e) => {
    e.preventDefault();
    
    // Ask user if they want to update their location
    const userConfirmed = window.confirm("Would you like to update your location?");
    
    if (userConfirmed) {
        if (navigator.geolocation) {
            setLoading(true); // Start loading state
            
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    // setLocation({ latitude, longitude });
                    // setLatitude(latitude);
                    // setLongitude(longitude);
                    // setError(null); // Clear any previous errors
                    setFormData((prev=>({
                      ...prev,latitude:latitude,longitude:longitude
                    })))
                    setLoading(false); // End loading state
                    notifyUser('Location Updated');
                    
                },
                (error) => {
                    setLoading(false); // End loading state
                    let errorMessage;
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = 'User denied the request for Geolocation.';
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = 'Location information is unavailable.';
                            break;
                        case error.TIMEOUT:
                            errorMessage = 'The request to get user location timed out.';
                            break;
                        case error.UNKNOWN_ERROR:
                        default:
                            errorMessage = 'An unknown error occurred.';
                    }
                    setError(errorMessage);
                    console.error(error);
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    } else {
        setError('Location update canceled by user.');
    }
  };

  

  return (
    <>
      <Modal   size='lg'show={show} onHide={handleClose} >
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <p>Record ID : {rowData.id}</p>
          <div className='d-flex justify-content-around'>
              {/* District Picklist */}
            
              <Form.Group controlId="district" style={{ marginBottom: 10,width:'250px' }} >
              <Form.Label>District</Form.Label>
              <Form.Control
                as="select"
                name="district"
                value={formData.district} // Set the value to selected data
                onChange={handleChange}
                disabled={rowData.canEdit}
              >
                <option value="">{formData.district}</option>
              </Form.Control>
            </Form.Group>

            {/* Taluka Picklist */}
            <Form.Group controlId="taluka" style={{ marginBottom: 10 , width:'250px' }} >
              <Form.Label>Taluka</Form.Label>
              <Form.Control
                as="select"
                name="taluka"
                value={formData.taluka} // Set the value to selected data
                onChange={handleChange}
                disabled={rowData.canEdit}
              >
                <option value="">{formData.taluka}</option>
              </Form.Control>
            </Form.Group>

</div>

          
<div className='d-flex justify-content-around'>
            {/* Village Picklist */}
            <Form.Group controlId="village" style={{ marginBottom: 10 ,width:'250px' }}>
              <Form.Label>Village</Form.Label>
              <Form.Control
                as="select"
                name="village"
                value={formData.village} // Set the value to selected data
                onChange={handleChange}
                disabled={rowData.canEdit}
              >
                <option value="">{formData.village}</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="MOBILE_NO" style={{ marginBottom: 10 ,width:'250px' }}>
              <Form.Label>Mobie Number</Form.Label>
              <Form.Control
                type="tel"
                name="MOBILE_NO"
                value={formData.MOBILE_NO} // Set the value to selected data
                onChange={handleChange}
                disabled={!rowData.canEdit}
              />
                
              
            </Form.Group>
            </div>
            {/* Location TextArea */}
            <Form.Group controlId="location" style={{ marginBottom: 10 }}>
              <Form.Label>Location (Address of Rain Water Harvesting Point)</Form.Label>
              <Form.Control
                // as="textarea"
                type='textarea'
                rows={3}
                name="location"
                value={formData.location}
               // disabled={true} // Set the value to selected data
                onChange={handleChange}
              />
            </Form.Group>
            <div className='d-flex justify-content-around'>
            <Form.Group controlId="funds" style={{ marginBottom: 10 , width:'250px'}}>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type='number'
                name="APPROX_AMOUNT"
                value={formData.APPROX_AMOUNT}
                onChange={handleChange}
                disabled={!rowData.canEdit} // Set the value to selected data
                
              />
            </Form.Group>
            <Form.Group controlId="taluka" style={{ marginBottom: 10 , width:'250px' }} >
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="Work_Status"
                value={formData.Work_Status} // Set the value to selected data
                onChange={handleChange}
                // disabled={!rowData.canEdit}
              >
                <option value="">-- Select an option --</option>
<option value="On Going">On Going</option>
<option value="Completed">Completed</option>
              </Form.Control>
            </Form.Group>
            

            </div>
           
            <div className='d-flex justify-content-around'>
           
              
            
            <Form.Group controlId="workName" style={{ marginBottom: 10,width:'250px' }}>
              <Form.Label>Structure Type</Form.Label>
              <Form.Control
                type="text"
                name="workName"
                disabled={rowData.canEdit}
                value={formData.workName} // Set the value to selected data
                onChange={handleChange}
              />
            </Form.Group>
          
            <Form.Group controlId="taluka" style={{ marginBottom: 10 , width:'250px' }} >
              <Form.Label>Area Type</Form.Label>
              <Form.Control
                as="select"
                name="AreaType"
                value={formData.AreaType} // Set the value to selected data
                onChange={handleChange}
                disabled={rowData.canEdit}
              >
                <option value="">{formData.AreaType}</option>
              </Form.Control>
            </Form.Group>

</div>

<div className='d-flex justify-content-around'>
              
            
              <Form.Group controlId="IMPLIMANTATION_AUTHORITY" style={{ marginBottom: 10, width:'250px'}}>
              <Form.Label></Form.Label>
              <Form.Control
                as="select"
                name="IMPLIMANTATION_AUTHORITY"
                disabled={!rowData.canEdit}
                value={formData.IMPLIMANTATION_AUTHORITY} // Set the value to selected data
                onChange={handleChange}
              >
                 <option value="">-- Select an option --</option>
       <option value="MGNREGA">MGNREGA</option>
       <option value="AMRUT">AMRUT</option>
       <option value="PMKSY">PMKSY</option>
       <option value="CAMPA">CAMPA</option>
       <option value="Finance Commission Grants">Finance Commission Grants</option>
       <option value="State Government Schemes">State Government Schemes</option>
       <option value="CSR Funds">CSR Funds</option>
       <option value="District Mineral Funds">District Mineral Funds</option>
       <option value="Community Fund">Community Fund</option>
       <option value="NGO/SHGs Fund">NGO/SHGs Fund</option>
       <option value="Philanthropic Contributions">Philanthropic Contributions</option>
       <option value="Industrial Donations">Industrial Donations</option>
       <option value="Crowd Funding">Crowd Funding</option>
       <option value="Individual Donors">Individual Donors</option>
       <option value="RIDF (Rural Infrastructure Dev. Fund)">
         RIDF (Rural Infrastructure Dev. Fund)
       </option>
       <option value="MP/MLA Local Area Development Scheme (LADS)">
         MP/MLA Local Area Development Scheme (LADS)
       </option>
       <option value="Other Central Fund like BADP">Other Central Fund like BADP</option>
       <option value="Municipal Corporation">Municipal Corporation</option>
       <option value="PEPL">PEPL</option>
       <option value="Others">Others</option>
              </Form.Control>
            </Form.Group>

            {/* Taluka Picklist */}
            <Form.Group controlId="Communities_Involved_Name" style={{ marginBottom: 10 , width:'250px' }} >
              <Form.Label>Community Involved</Form.Label>
              <Form.Control
                as="select"
                name="Communities_Involved_Name"
                value={formData.Communities_Involved_Name} // Set the value to selected data
                onChange={handleChange}
                disabled={!rowData.canEdit}
              >
                <option value="">-- Select an option --</option>
                <option value="SHG (Self Help Gr.)">SHG (Self Help Gr.)</option>
                <option value="WUA (Water User Association)">WUA (Water User Association)</option>
                <option value="NGO">NGO</option>
                <option value="CSR/Private"> CSR/Private</option>
                <option value="Others">Others</option>
              </Form.Control>
            </Form.Group>

</div>

<div className='d-flex , justify-content-around'>
            {/* Inauguration Date */}
            <Form.Group controlId="inaugurationDate" style={{ marginBottom: 10 , width:'250px' }}>
              <Form.Label>Start Work Date</Form.Label>
              <Form.Control
                type="date"
                name="inaugurationDate"
                value={formData.inaugurationDate} // Set the value to selected data
                onChange={handleChange}
                disabled={!rowData.canEdit}
              />
            </Form.Group>
            
            {/* Completion Date */}
            <Form.Group controlId="completionDate" style={{ marginBottom: 10, width:'250px' }}>
              <Form.Label>Completion Date</Form.Label>
              <Form.Control
                type="date"
                name="completionDate"
                value={formData.completionDate} // Set the value to selected data
                onChange={handleChange}
                disabled={!rowData.canEdit}
              />
            </Form.Group>

            </div>
<div className='d-flex justify-content-around'>
<div>


            {/* Inauguration Photo */}
            {
              rowData.canEdit ? 
              <Form.Group controlId="inaugurationPhoto" style={{ marginBottom: 10,width:'250px' }}>
                <Form.Label>Start Work Photo</Form.Label>
                <Form.Control type="file" name="inaugurationPhoto"  onChange={handleChange}/>
                {/* Show the image if base64 is available */}
                {formData.inaugurationPhotoBase64 && (
                  <Image
                  src={formData.inaugurationPhotoBase64}
                  thumbnail
                    style={{ marginTop: 10 }}
                  />
                )}
                {
                formData.inaugurationPhoto && (
                  <Image
                  src={formData.inaugurationPhoto}
                  thumbnail
                    style={{ marginTop: 10 }}
                  />
                )
                }
              </Form.Group> 
              :
              null
            }
          
            
</div>
<div>


            {
              rowData.canEdit ? 
              <Form.Group controlId="completionPhoto" style={{ marginBottom: 10 ,width:'250px'}}>
                <Form.Label>Completion Photo</Form.Label>
                <Form.Control type="file" name="completionPhoto" onChange={handleChange} />
                {/* Show the image if base64 is available */}
                {formData.completionPhotoBase64 && (
                  <Image
                    src={formData.completionPhotoBase64}
                    style={{ marginTop: 10 }}
                    thumbnail
                  />
                )}
                {formData.completionPhoto && (
                  <Image
                    src={formData.completionPhoto}
                    style={{ marginTop: 10 }}
                    thumbnail
                  />
                )}
              </Form.Group>
              :
              null
            }           
            {/* Completion Photo */}
</div>
</div>
 {
    rowData.canEdit ? 
    <div className='d-flex justify-content-around mb-2'>
<div style={{width:'150px'}}>
                <button className='btn btn-primary' onClick={handleLocationCapture}>Update Location</button>
  </div>
  <div className='d-flex' style={{width:'150px'}}>
              {/* <label htmlFor="Latitude">&nbsp;Latitude :&nbsp;</label>
              <input style={{width:"102px" , padding:'0px'}}
               type="number"
                name='latitude' 
                value={rowData.latitude} 
                 onChange={handleChange} /> */}
                  <Form.Group controlId="funds" style={{ marginBottom: 10 , width:'200px'}}>
              <Form.Label>Latitude :</Form.Label>
              <Form.Control
                type='number'
                name="latitude"
                value={formData.latitude}
                onChange={handleChange}
                disabled={!rowData.canEdit} // Set the value to selected data
                
              />
            </Form.Group>


  </div>
  <div className='d-flex' style={{width:'150px'}}>
                {/* <label htmlFor="Longitude" >Longitude :&nbsp;</label>
                <input style={{width:"102px"}}
                 type="number" name='longitude'
                  value={formData.longitude}
                   onChange={handleChange} /> */}
                   <Form.Group controlId="funds" style={{ marginBottom: 10 , width:'200px'}}>
              <Form.Label>Longitude :</Form.Label>
              <Form.Control
                type='number'
                name="longitude"
                value={formData.longitude}
                onChange={handleChange}
                disabled={!rowData.canEdit} // Set the value to selected data
                
              />
            </Form.Group>

  </div>
</div>
    : null
  }
     {/* Submit Button */}
            {console.log(349,)}
            {
              rowData.canEdit ? 
                <Button variant="primary" type="submit">
                  Submit
                </Button>
                :
                null
            }
           
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyModal;
